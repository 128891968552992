<template>
  <div>
    <el-form>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="请选择年度" required class="flex">
            <el-date-picker
              v-model="value.year"
              value-format="yyyy"
              type="year"
              :readonly="disabled"
              placeholder="选择年"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :md="8">
          <el-form-item label="添加节假日" required class="flex add">
            <i  @click="addTable" class="el-icon-circle-plus-outline" v-if="!disabled"></i>
            <span @click="addTable" v-if="!disabled">添加</span>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-for="(item, index) in sfaSalaryDateReqVoList" :key="index">
        <el-row :gutter="24">
          <el-col :md="8">
            <el-form-item label="节日名称" class="flex">
              <el-input
                :readonly="disabled"
                placeholder="请输入节日名称"
                v-model="item.salary"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="12">
            <el-form-item label="选择日期" class="flex">
              <el-date-picker
                value-format="yyyy-MM-dd"
                v-model="item.salaryDate"
                :readonly="disabled"
                @change="getDayChange(index, $event)"
                type="daterange"
                :picker-options="pickerOption"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :md="4" class="add" v-if="index && !disabled">
            <i @click="delTable(index, 'main')" class="el-icon-delete del"></i>
            <span @click="delTable(index, 'main')">删除</span>
          </el-col>
        </el-row>
        <vxe-table
          :edit-config="{
            trigger:'click',
            mode: 'row',
            activeMethod: activeCellMethod,
          }"
          ref="xTable"
          :data="item.salaryDaysList"
          style="margin-bottom: 20px"
        >
          <vxe-table-column field="day" title="日期"></vxe-table-column>
          <vxe-table-column
            field="isCalculationSalary"
            title="是否计算薪资"
            :edit-render="{
              name: '$select',
              options: options,
            }"
          ></vxe-table-column>
          <vxe-table-column title="操作" v-if="!disabled">
             <template v-slot="{ row, rowIndex }">
               <span class="cursor" @click="delTable(row, 'table',rowIndex, index)">删除</span>
             </template>
          </vxe-table-column>
        </vxe-table>
      </div>
    </el-form>
  </div>
</template>
<script>
import request from '../../../../../../../utils/request';

export default {
  props: {
    value: Object,
    disabled: Boolean,
  },
  data() {
    return {
      options: [],
      sfaSalaryDateReqVoList: [{
        salary: '',
        salaryEndDate: '',
        salaryStartDate: '',
        salaryDate: [],
        salaryDaysList: [],
      }],
      pickerOption: {
        // disabledDate: (time) => time.getTime() < new Date() - 8.64e7,

        disabledDate: (time) => {
          if (this.value.year) {
            const nowDate = this.getCurrentYearTime(this.value.year);
            return time.getTime() < nowDate.start || time.getTime() > nowDate.end;
          }
          return true;
        },
      },
    };
  },
  created() {
    this.getDic();
    this.$emit('input', this.value);
  },
  watch: {
    value: {
      deep: true,
      handler() {
        this.sfaSalaryDateReqVoList = this.value.salaryDateRespVoList || [];
      },
    },
  },
  methods: {
    // 获取某年可选时间区间
    getCurrentYearTime(year) {
      const startTime = new Date(year, 0, '01'); // 获取本年1月1号
      const endDate = new Date(year, 12, '01'); // 获取本年12月的第一天日期
      const endTime = new Date(endDate.getTime() - 1000 * 24 * 60 * 60); // 获取12月的最后一天
      return { start: startTime, end: endTime };
    },
    getDic() {
      request.post('/mdm/mdmdictdata/batchDictSelect', ['YesNoCodeNumber']).then((res) => {
        res.result.YesNoCodeNumber.map((v) => {
          const item = v;
          item.label = item.dictValue;
          item.value = item.dictCode;
          return item;
        });
        this.options = res.result.YesNoCodeNumber;
      });
    },
    // 查看时禁用表格编辑
    activeCellMethod({ column, columnIndex }) {
      console.log(column);
      if (this.disabled && columnIndex === 1) {
        return false;
      }
      return true;
    },
    // 添加节假日——选择日期
    getDayChange(index, e) {
      const [start, end] = e;
      const dates = this.getDayAll(start, end);
      this.sfaSalaryDateReqVoList[index].salaryDaysList = [];
      dates.map((v, k) => {
        const item = v;
        this.sfaSalaryDateReqVoList[index].salaryDaysList.push({
          day: item,
          isCalculationSalary: '0',
        });
        return item;
      });
      this.sfaSalaryDateReqVoList[index].salaryStartDate = start;
      this.sfaSalaryDateReqVoList[index].salaryEndDate = end;
      this.value.sfaSalaryDateReqVoList = this.sfaSalaryDateReqVoList;
      this.$emit('input', this.value);
    },
    // 获取选中的时间范围
    getDayAll(starDay, endDay) {
      const arr = [];
      const dates = [];
      const db = new Date(starDay);
      const de = new Date(endDay);
      const s = db.getTime() - 24 * 60 * 60 * 1000;
      const d = de.getTime() - 24 * 60 * 60 * 1000;
      // 获取到两个日期之间的每一天的毫秒数
      for (let i = s; i <= d;) {
        i += 24 * 60 * 60 * 1000;
        arr.push(parseInt(i, 0));
      }
      // 获取每一天的时间  YY-MM-DD
      arr.map((v) => {
        const item = v;
        const time = new Date(v);
        const year = time.getFullYear(time);
        const mouth = (time.getMonth() + 1) >= 10 ? (time.getMonth() + 1) : (`0${time.getMonth() + 1}`);
        const day = time.getDate() >= 10 ? time.getDate() : (`0${time.getDate()}`);
        const YYMMDD = `${year}-${mouth}-${day}`;
        dates.push(YYMMDD);
        return item;
      });
      return dates;
    },
    // 删除表格某一行数据
    delTable(row, type, rowIndex, index) {
      if (type === 'table') {
        this.sfaSalaryDateReqVoList[index].salaryDaysList.splice(rowIndex, 1);
      } else {
        this.sfaSalaryDateReqVoList.splice(row, 1);
      }
      this.value.sfaSalaryDateReqVoList = this.sfaSalaryDateReqVoList;
      this.$emit('input', this.value);
    },
    // 添加
    addTable() {
      this.sfaSalaryDateReqVoList.push({
        salary: '',
        salaryEndDate: '',
        salaryStartDate: '',
        salaryDate: [],
        salaryDaysList: [],
      });
    },
  },
};
</script>
<style lang="less" scoped>
.flex {
  display: flex;
}
.el-row {
  margin: 0 !important;
}
/deep/ .el-form-item__label {
  width: 125px;
}
/deep/ .el-form-item__content {
  flex: 1;
}
.el-col {
  padding: 0 !important;
}
.add i,
.add span {
  font-size: 14px;
  cursor: pointer;
}
.cursor{
  cursor: pointer;
}
.add i {
  margin-right: 5px;
}
.del {
  margin-left: 20px;
}
</style>
