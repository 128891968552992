<script>
import TablePage from '../../../../../components/table_page';
import request from '../../../../../utils/request';
import Form from './form';

export default {
  name: 'table-component',
  extends: TablePage,
  components: {
    Form,
  },
  data() {
    return {
      requestUrl: '/sfa/sfaCalculateSalaryYearController/list',
      /** @desc 表单配置参数 */
      formConfig: {},
      /** @desc 请假参数配置 */
      formData: {
        year: '',
      },
      /** @desc 弹窗配置参数 */
      modalConfig: {
        title: '新增',
        visible: false,
        type: '',
      },
      options: [],
    };
  },
  async created() {
    await this.getConfigList('sfa_attendance_calculate_salary_date');
    await this.getDict();
    this.searchList.map((v, k) => {
      const item = v;
      if (item.field === 'year') {
        this.$set(this.searchList[k].render, 'options', this.options);
        this.$forceUpdate();
      }
      return item;
    });
  },
  methods: {
    async getDict() {
      const resData = await request.get('/sfa/sfaCalculateSalaryYearController/getYearList');
      resData.result.map((v) => {
        const item = v;
        this.options.push({
          label: item,
          value: item,
        });
        return item;
      });
    },
    cellClick({ row, column }) {
      if (column.property === 'year') {
        this.formConfig = {
          type: 'view',
          id: row.id,
        };
        this.modalConfig.title = '查看';
        this.openFull();
      }
    },
    modalClick({ val, row }) {
      let urls = '';
      let text = '';
      if (val.code === 'add') {
        this.formConfig.type = val.code;
        this.openFull();
      } else if (val.code === 'edit') {
        this.formConfig.type = val.code;
        this.formConfig.id = row.id;
        this.modalConfig.title = '编辑';
        this.openFull();
      } else if (val.code === 'enable') {
        urls = '/sfa/sfaCalculateSalaryYearController/enable';
        text = '启用';
        this.operationClick(urls, text);
      } else if (val.code === 'disable') {
        urls = '/sfa/sfaCalculateSalaryYearController/disable';
        text = '禁用';
        this.operationClick(urls, text);
      }
    },
    operationClick(urls, text) {
      let datas = [];
      if (this.selection.length > 0) {
        datas = this.selection;
        request.post(urls, datas).then((res) => {
          if (res.success) {
            this.$message({
              type: 'success',
              message: `${text}成功`,
            });
            this.selection = [];
            this.getList();
          }
        });
      } else {
        this.$message({
          type: 'error',
          message: '请选择数据',
        });
      }
    },
  },
};
</script>
