var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        [
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex",
                      attrs: { label: "请选择年度", required: "" },
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          "value-format": "yyyy",
                          type: "year",
                          readonly: _vm.disabled,
                          placeholder: "选择年",
                        },
                        model: {
                          value: _vm.value.year,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "year", $$v)
                          },
                          expression: "value.year",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "el-col",
                { attrs: { md: 8 } },
                [
                  _c(
                    "el-form-item",
                    {
                      staticClass: "flex add",
                      attrs: { label: "添加节假日", required: "" },
                    },
                    [
                      !_vm.disabled
                        ? _c("i", {
                            staticClass: "el-icon-circle-plus-outline",
                            on: { click: _vm.addTable },
                          })
                        : _vm._e(),
                      !_vm.disabled
                        ? _c("span", { on: { click: _vm.addTable } }, [
                            _vm._v("添加"),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.sfaSalaryDateReqVoList, function (item, index) {
            return _c(
              "div",
              { key: index },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { md: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "节日名称" } },
                          [
                            _c("el-input", {
                              attrs: {
                                readonly: _vm.disabled,
                                placeholder: "请输入节日名称",
                              },
                              model: {
                                value: item.salary,
                                callback: function ($$v) {
                                  _vm.$set(item, "salary", $$v)
                                },
                                expression: "item.salary",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { md: 12 } },
                      [
                        _c(
                          "el-form-item",
                          { staticClass: "flex", attrs: { label: "选择日期" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                "value-format": "yyyy-MM-dd",
                                readonly: _vm.disabled,
                                type: "daterange",
                                "picker-options": _vm.pickerOption,
                                "range-separator": "至",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "结束日期",
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.getDayChange(index, $event)
                                },
                              },
                              model: {
                                value: item.salaryDate,
                                callback: function ($$v) {
                                  _vm.$set(item, "salaryDate", $$v)
                                },
                                expression: "item.salaryDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    index && !_vm.disabled
                      ? _c("el-col", { staticClass: "add", attrs: { md: 4 } }, [
                          _c("i", {
                            staticClass: "el-icon-delete del",
                            on: {
                              click: function ($event) {
                                return _vm.delTable(index, "main")
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.delTable(index, "main")
                                },
                              },
                            },
                            [_vm._v("删除")]
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
                _c(
                  "vxe-table",
                  {
                    ref: "xTable",
                    refInFor: true,
                    staticStyle: { "margin-bottom": "20px" },
                    attrs: {
                      "edit-config": {
                        trigger: "click",
                        mode: "row",
                        activeMethod: _vm.activeCellMethod,
                      },
                      data: item.salaryDaysList,
                    },
                  },
                  [
                    _c("vxe-table-column", {
                      attrs: { field: "day", title: "日期" },
                    }),
                    _c("vxe-table-column", {
                      attrs: {
                        field: "isCalculationSalary",
                        title: "是否计算薪资",
                        "edit-render": {
                          name: "$select",
                          options: _vm.options,
                        },
                      },
                    }),
                    !_vm.disabled
                      ? _c("vxe-table-column", {
                          attrs: { title: "操作" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var row = ref.row
                                  var rowIndex = ref.rowIndex
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "cursor",
                                        on: {
                                          click: function ($event) {
                                            return _vm.delTable(
                                              row,
                                              "table",
                                              rowIndex,
                                              index
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v("删除")]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }